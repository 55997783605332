.scroll {
    height: 50px;	
    overflow: hidden;
    /* background-color: #F5FFEF; */
    border-top: 1px solid rgba(145, 158, 171, 0.12);;
    border-bottom: 1px solid rgba(145, 158, 171, 0.12);;
    /* position: relative; */
    margin-bottom: 25px
  }

.coins {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.big-pad {
  padding: 0 150px 0 150px;
}

.cont{
  height: 100%!important;
  position: relative;
}

.fixed-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: white;
}

.farm-token {
  position: "absolute"; 
  margin-top: "-35px"; 
  margin-left: "25px"
}

.overlay::before, .overlay::after {
  background: transparent!important;
  content: "";
  height: 100%;
  position: absolute;
  width: var(--gradient-width);
  z-index: 2;
}

.fddd {
  width: 100%!important;
  height: 500px!important;
}

.fddd[svg] {
  width: 100%!important;
}

.hover-primary:hover {
  background-color: forestgreen!important;
  cursor: pointer!important;
  color: white;
}

.cardStyle {
  width: 700px!important;
  padding: 16px!important; 
  margin-top: 30px!important; 
  box-shadow: 0!important;
  border-radius: 0!important;
}

.cardExtraStyle {
  position: absolute!important; 
  left: 28%!important;
  top: 40%!important;
  margin: 0 auto!important;
}

.countdown {
  text-align: center!important; 
  font-size: 60px!important;
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .big-pad {
      padding: 0 0;
    }
    .network-icon {
      display: none!important;
    }
}

@media(max-width: 767px) {
  .no-display {
    display: none !important;
  }
  .scroll {
    white-space: nowrap;
  }
  .coins {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }

  .big-pad {
    padding: 0 0;
  }

  .network-icon {
    display: none!important;
  }
  .cardStyle {
    width: 300px!important;
    margin: 20px!important;
  }
  .countdown {
    font-size: 50px!important;
  }
}
  